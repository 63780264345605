<template>
  <div id="app">
    <div class="login-page">
      <transition name="fade">
        <div v-if="!registerActive" class="wallpaper-login"></div>
      </transition>
      <div class="wallpaper-register"></div>

      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
            <div
              v-if="!registerActive"
              class="card login"
              v-bind:class="{ error: emptyFields }"
            >
              <div style="margin-bottom:20px;margin-left:70px">
                <img src="@/assets/asco.png" height="120" width="115" />
              </div>
              <form
                class="form-group"
                @submit.prevent="userLogin"
                autocomplete="off"
              >
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  required
                />
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  placeholder="Wachtwoord"
                  required
                />
                <div style="margin-left:100px">
                  <b-button type="submit" variant="primary">
                    Inloggen
                  </b-button>
                </div>
                <div style="margin-bottom:20px;margin-top:20px">
                  <p class="text-center">
                    Wachtwoord vergeten?
                    <a
                      href="#"
                      @click="
                        (registerActive = !registerActive),
                          (emptyFields = false)
                      "
                      >Klik hier</a
                    >
                  </p>
                      <p class="text-center"> <a style="color:red;" href="https://ameliewebdesign.nl"> Website laten maken </a></p>
                </div>
              </form>
            </div>

            <div
              v-else
              class="card register"
              v-bind:class="{ error: emptyFields }"
            >
              <div style="margin-bottom:20px;margin-left:70px">
                <img src="@/assets/asco.png" height="120" width="145" />
              </div>
              <form class="form-group">
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  placeholder="E-mail"
                  required
                />

                <div style="margin-left:90px">
                  <b-button
                    type="submit"
                    variant="primary"
                    @click="resetPassword()"
                  >
                    Resetten</b-button
                  >
                </div>
                <div style="margin-bottom:20px;margin-top:20px">
                  <p>
                    Al een wachtwoord?
                    <a
                      href="#"
                      @click="
                        (registerActive = !registerActive),
                          (emptyFields = false)
                      "
                      >Login</a
                    >
                  </p>
                </div>
              </form>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import axios from "axios";

export default {
  data() {
    return {
      emptyFields: false,
      registerActive: false,
      email: "",
      password: "",
      passwordFieldType: "password",
      error: false,
    };
  },
  methods: {
    userLogin() {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.s$swal.resumeTimer);
        },
      });
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async () => {
          this.$router.push("/Portaal");
          const token = await firebase.auth().currentUser.getIdToken(true);
          if (token) axios.defaults.headers.common["fireToken"] = token;
          const response = await axios.get(
            `https://backend.mcmakelaardij.nl/api/whoami`
          );
          if (["MAKELAAR", "BEHEERDER"].includes(response.data.accessRight)) {
            this.$router.push("Portaal");
        
          }
          if (["ADMIN", "WERKNEMER"].includes(response.data.accessRight)) {
               this.$router.push("/");
            this.$swal("Geen of onjuiste rechten");
          }
          if (["UNDETERMINED"].includes(response.data.accessRight)) {
            this.$router.push("/");
            Toast.fire({
              icon: "error",
              title: "Rechten onbekend! Neem contact op met een beheerder.",
            });
            firebase.auth().signOut();
            return;
          }

          Toast.fire({
            icon: "success",
            title: "Succesvol ingelogd",
          });
        })
        //.catch((error) => {
        .catch(() => {
         // this.$swal.fire("wachtwoord/gebruikersnaam is onjuist");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    resetInput() {
      this.$refs["email"].value = "";
      this.$refs["password"].value = "";
    },

    resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Wachtwoord link verzonden",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(() => {
          this.$swal.fire("Bericht is verzonden");
        });
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1rem;
}

.card {
  padding: 20px;
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}
.wallpaper-login {
  background-image: url("../../assets/background3.jpg");

  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.wallpaper-register {
  background-image: url("../../assets/background3.jpg");

  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.wallpaper-register h1 {
  margin-bottom: 1.5rem;
}

.error {
  animation-name: errorShake;
  animation-duration: 0.3s;
}

@keyframes errorShake {
  0% {
    transform: translateX(-25px);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
