
<template>
  <LoginForm />
</template>

<script>
import LoginForm from "@/components/view/LoginForm";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    LoginForm,
  },
  metaInfo: {
    title: "Asco Software | Inloggen",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>